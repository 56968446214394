import Alpine from 'alpinejs';
import { gsap } from 'gsap';
window.Alpine = Alpine;
window.gsap = gsap;

import timerCards from '@modules/TimerCards';
Alpine.data('timerCards', timerCards);

window.onload = () => {
    Alpine.start();

    console.log(
        `%cCrafted with ❤ by https://whiteboard.is`,
        'background: #F26522; color: #000000; font-size: 13px; padding: 4px 8px;'
    );
};
