export default () => ({
    vehicles: null,
    active: 1,
    total: 1,
    time: '00:05',

    init() {
        console.log(`Timer Cards is Active!`);
        this.vehicles = document.querySelectorAll('[data-vehicle]');
        this.total = this.vehicles.length;

        for (let i = 0; i < this.total; i++) {
            if (i > 0) {
                gsap.set(this.vehicles[i], { xPercent: 101 });
            }
        }

        this.$watch('active', () => this.open());
        this.setTimer();
    },

    setTimer() {
        this.time = '00:05'

        setTimeout(() => {
            this.time = '00:04'
        }, 1000);

        setTimeout(() => {
            this.time = '00:03'
        }, 2000);

        setTimeout(() => {
            this.time = '00:02'
        }, 3000);

        setTimeout(() => {
            this.time = '00:01'
        }, 4000);

        setTimeout(() => {
            this.time = 'SOLD'
            this.close();
        }, 5000);

    },

    changeCard() {
        const next = this.active + 1;

        if (next <= this.total) {
            this.active = next;
        } else {
            this.active = 1;
        }
    },


    open() {
        this.setTimer();
        gsap.to(this.vehicles[this.active-1], {
            duration: 0.5,
            xPercent: 0,
            ease: 'quint.out',
        });
    },

    close() {
        setTimeout(() => {
            gsap.to(this.vehicles[this.active-1], {
                duration: 0.5,
                xPercent: 101,
                ease: 'quint.in',
                onComplete: () => {
                    this.changeCard();
                }
            });
        }, 200);
    },

})
